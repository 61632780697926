
export const fadeIn = {
    before: {
        opacity: 0,
    },
    after: {
        opacity: 1,
        transition: {
            staggerChildren: 0.1,
            ease: "easeOut",
            duration: 0.5,
        }
    }
}

export const headerRevealv1 = {
    before: {
        opacity: 0,
        y: 300
    },
    after: {
        opacity: 1,
        y:0,
        transition: {
            ease: "easeOut",
            when: "beforeChildren",
            duration: 0.5   
        }
    }
}

export const pushItemLeft = {
    before: {
        opacity: 0,
        x: -300
    },
    after: {
        opacity: 1,
        x:0,
        transition: {
            ease: "easeOut",
            when: "beforeChildren",
            duration: 0.5   
        }
    }
}

export const headerBlackBar = {
    before: {
        opacity: 0,
        x: -300,
    },
    after: {
        opacity:1,
        x: 0,
        transition: {
            duration: 0.25,
            ease: "easeOut"
        }   
    }
}

export const pushItemUp = {
    before: {
        opacity: 0,
        y: 100,
    },
    after: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.5,
            ease: "easeOut"
        }
    }
}

export const pushItemDown = {
    before: {
        opacity: 0,
        y: -100,
    },
    after: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.5,
            ease: "easeOut"
        }
    }
}

export const parentAnimation = {
    before: {
        opacity: 1,
    },
    after: {
        opacity: 1,
        transition: {
            staggerChildren: 0.01,
            ease: "easeOut",
            when: "beforeChildren",
            duration: 0.01,
        }
    }
}